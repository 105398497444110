import axios from "axios";

import { UPDATE_TASKS } from "./tasks";
import { UPDATE_CLIENT_PRODUCTS } from "./products";
import { SET_NOTIFICATIONS } from "./notifications";

const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
const CHANGE_REGISTRATION_STEP = "CHANGE_REGISTRATION_STEP";

export const UPDATE_WAITING_FOR_REMOTE_CONTROL =
  "UPDATE_WAITING_FOR_REMOTE_CONTROL";

export default function(state = {}, action) {
  switch (action.type) {
    case UPDATE_USER_DETAILS: {
      return { ...state, ...action.payload };
    }
    case CHANGE_REGISTRATION_STEP: {
      return { ...state, registerStep: action.payload };
    }
    case UPDATE_WAITING_FOR_REMOTE_CONTROL: {
      return { ...state, updateForRemoteControl: action.payload };
    }
    default: {
      return state;
    }
  }
}

export const updateUserDetails = data => {
  return { type: UPDATE_USER_DETAILS, payload: data };
};

export const changeRegistrationStep = step => {
  return { type: CHANGE_REGISTRATION_STEP, payload: step };
};

export const executeServerPingProducts = () => {
  return async function(dispatch, getState) {
    const state = getState();
    const { data } = await axios.post(`${process.env.SERVER_URL}/pingProducts`);

    dispatch({
      type: UPDATE_CLIENT_PRODUCTS,
      payload: { products: data.products, tasks: state.tasks }
    });
  };
};

export const executeServerPingTasks = intl => {
  return async function(dispatch) {
    try {
      const { data } = await axios.post(`${process.env.SERVER_URL}/pingTasks`);

      dispatch({
        type: UPDATE_TASKS,
        payload: { data: data.tasks, lastRequest: +new Date() }
      });

      if (data.answeredTickets > 0) {
        dispatch({
          type: SET_NOTIFICATIONS,
          payload: [
            {
              img: "/assets/img/notification-thumb-3.jpg",
              title: intl.formatMessage(
                { id: "notifications.new-tickets" },
                { number: data.answeredTickets }
              ),
              date: "09.04.2018 - 12:45",
              link: "/app/support-center/my-tickets"
            }
          ]
        });
      }

      dispatch({
        type: UPDATE_WAITING_FOR_REMOTE_CONTROL,
        payload: data.waitingForRemoteControl
      });
    } catch (err) {
      dispatch({ type: UPDATE_TASKS, payload: { lastRequest: null } });
    }
  };
};
