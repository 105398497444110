import produce from "immer";
import { ADD_LOCAL_TASK } from "./tasks";

const UPDATE_PRODUCT = "UPDATE_PRODUCT";

export const UPDATE_CLIENT_PRODUCTS = "UPDATE_CLIENT_PRODUCTS";

export default function(state = null, action) {
  switch (action.type) {
    case UPDATE_CLIENT_PRODUCTS: {
      return action.payload.products.map(p => {
        p.status = p.status.toLowerCase();

        if (!state) {
          return p;
        }

        if (!p.serverIsLive) {
          p.qemuStatus = { status: "terminated" };
        }

        return p;
      });
    }
    case UPDATE_PRODUCT: {
      if (!state) {
        return null;
      }

      const productIndex = state.findIndex(p => p._id === action.payload.id);

      return produce(state, draftState => {
        let waitingForStatusBackup = undefined;

        if (draftState[productIndex]) {
          waitingForStatusBackup = draftState[productIndex].waitingForStatus;
        }

        draftState[productIndex] = action.payload;

        draftState[productIndex] = produce(
          draftState[productIndex],
          draftProduct => {
            draftProduct.waitingForStatus = waitingForStatusBackup;
          }
        );
      });
    }
    case ADD_LOCAL_TASK: {
      return produce(state, draftState => {
        const product = draftState.find(
          p => p._id === action.payload.productID
        );
        product.isWorking = true;
      });
    }
    default: {
      return state;
    }
  }
}

export const updateProduct = payload => {
  return { type: UPDATE_PRODUCT, payload };
};
