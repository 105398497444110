import produce from "immer";

export const ADD_LOCAL_TASK = "ADD_LOCAL_TASK";
export const REMOVE_LOCAL_TASK = "REMOVE_LOCAL_TASK";
export const UPDATE_TASKS = "UPDATE_TASKS";

let counter = 0;

export default function(state = null, action) {
  switch (action.type) {
    case ADD_LOCAL_TASK:
      return produce(state, draftState => {
        draftState.data.unshift(action.payload);
      });
    case REMOVE_LOCAL_TASK:
      return produce(state, draftState => {
        draftState.data = draftState.data.filter(d => d.id !== action.payload);
      });
    case UPDATE_TASKS:
      return {
        data: action.payload.data,
        lastRequest: action.payload.lastRequest
      };
    default:
      return state;
  }
}

export const addLocalTask = taskData => {
  return { type: ADD_LOCAL_TASK, payload: taskData };
};

export const removeLocalTask = taskID => {
  return { type: REMOVE_LOCAL_TASK, payload: taskID };
};

export const getLocalTaskToAdd = (productID, type, id = false) => {
  return {
    id: id || `${+new Date()}_${counter++}`,
    step: 0,
    productID: productID,
    type: type,
    createdAt: +new Date()
  };
};
