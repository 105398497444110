import { defaultLocale, localeOptions } from "../constants/defaultValues";

export const CHANGE_LOCALE = "CHANGE_LOCALE";
const SET_REGISTRATION_MODAL_OPEN = "SET_REGISTRATION_MODAL_OPEN";

const INIT_STATE = {
  locale:
    localStorage.getItem("currentLanguage") &&
    localeOptions.filter(x => x.id === localStorage.getItem("currentLanguage"))
      .length > 0
      ? localStorage.getItem("currentLanguage")
      : defaultLocale,
  isRegistrationModalOpen: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };

    case SET_REGISTRATION_MODAL_OPEN: {
      return { ...state, isRegistrationModalOpen: action.status };
    }

    default:
      return { ...state };
  }
};

export const changeLocale = locale => {
  localStorage.setItem("currentLanguage", locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale
  };
};

export const setRegistrationModalOpen = status => {
  return { type: SET_REGISTRATION_MODAL_OPEN, status };
};
