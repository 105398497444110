// const initialState = {
//   unread: 3,
//   data: [
//     {
//       img: "/assets/img/profile-pic-l-2.jpg",
//       title: "Joisse Kaycee just sent a new comment!",
//       date: "09.04.2018 - 12:45",
//       id: 1
//     },
//     {
//       img: "/assets/img/notification-thumb.jpg",
//       title: "1 item is out of stock!",
//       date: "09.04.2018 - 12:45",
//       id: 2
//     },
//     {
//       img: "/assets/img/notification-thumb-2.jpg",
//       title: "New order received! It is total $147,20.",
//       date: "09.04.2018 - 12:45",
//       id: 3
//     },
//     {
//       img: "/assets/img/notification-thumb-3.jpg",
//       title: "3 items just added to wish list by a user!",
//       date: "09.04.2018 - 12:45",
//       id: 4
//     }
//   ]
// };

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

const initialState = {
  unread: 0,
  data: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      let counter = 1;
      action.payload.forEach(data => (data.id = counter++));
      return { unread: action.payload.length, data: [...action.payload] };
    }
    default:
      return state;
  }
}
