import { combineReducers } from "redux";
import settings from "./settings";
import menu from "./menu";
import authUser from "./auth";
import user from "./user";
import notifications from "./notifications";
import tasks from "./tasks";
import products from "./products";

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  user,
  notifications,
  tasks,
  products
});

export default reducers;
